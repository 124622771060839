import api from '@/api/api'
import config from '@/config'

export async function calculateZoning(data) {
  return await api.post(
    `${config.backend}/projects/${data.projectId}/aois/${data.AoiId}/images/${data.imageId}/index-zones`,
    data.params,
  )
}

export async function saveZone(data) {
  return await api.put(
    `${config.backend}/projects/${data.projectId}/aois/${data.AoiId}/images/${data.imageId}/index-zones/${data.zoneId}`,
    data.params,
  )
}

export async function getSavedZone(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.AoiId}/index-zones`, {
    per_page: 'all',
  })
}

export async function getMapDetail(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.AoiId}/index-zones/${data.zoneId}`)
}

export async function deleteMap(data) {
  return await api.delete(`${config.backend}/projects/${data.projectId}/aois/${data.AoiId}/index-zones/${data.zoneId}`)
}
