<template>
  <v-layout class="overflow-y-hidden" style="height: 100%; background: transparent; border-radius: 0;">
    <v-card class="AOI-menu custom-card-bg-gradient" height="100%" style="max-width:280px">
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <ZoningBar
        v-if="currentField"
        ref="zoning"
        :currentField.sync="currentField"
        :images.sync="images"
        :index.sync="type"
        @addToMap="addZoningLayer"
        @back="destroyChart"
        :isIndexMonitoring="true"
        @removeAllLayer="() => $refs.map.removeAllLayer()"
        @removeLayer="id => $refs.map.removeLayer(id)"
        @selectMonth="selectMonth"
      />
      <AOIManagement
        :canCollapse="false"
        :canOrder="false"
        :AOIMenu.sync="AOIMenu"
        :isIndexMonitoring="true"
        :isCreating.sync="isCreating"
        @startDraw="startDraw"
        @editAOI="editAOI"
        @removeAOI="removeAOI"
        @makeOrder="AOI => $refs.makeOrderDialog.openDialog(AOI)"
        @zoomTo="bbox => $refs.map.submitZoom(bbox)"
        v-show="!currentField"
        ref="fieldControl"
        :customLegend.sync="customLegend"
        :dates.sync="dates"
        :drawing.sync="drawing"
        :field.sync="currentField"
        :index.sync="type.code"
        :is-draw="false"
        :legendMenu.sync="legendMenu"
        :listField.sync="listField"
        :loading.sync="loading"
        :mapLegend.sync="mapLegend"
        :sync.sync="sync"
        :typeIndex.sync="type"
        @addAOIToMap="addToMap"
        @changeIndex="changeIndex"
        @changeStatusMap="changeMapView"
        @closeColorPicker="() => $refs.map.closeColorPicker()"
        @destroyChart="destroyChart"
        @displayChartData="displayChartData"
        @download="download"
        @mapSize="() => $refs.map.reSize()"
        @openAddForm="openAddForm"
        @openDrawTool="openDrawTool"
        @refreshMap="() => $refs.map.reloadMap()"
        @removeAll="removeAllMap"
        @removeLayer="layerId => $refs.map.removeLayer(layerId)"
        @resetDraw="() => $refs.map.resetDraw"
        @selectMonth="selectMonth"
        @showLayerControl="isShowlayerControl = !isShowlayerControl"
        @updateDate="changeDate"
        @zoomToField="bbox => $refs.map.submitZoom(bbox)"
      >
      </AOIManagement>
    </v-card>
    <v-layout class="pl-4" column style="height: 100%;">
      <v-card style="width: 100%; height: 100%">
        <v-tooltip v-if="currentField" color="#000000c4" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              color="bgContainer"
              height="38"
              min-height="0"
              min-width="0"
              style="position: absolute; top: 145px; right: 5px; z-index: 2"
              v-bind="attrs"
              width="38"
              @click="changeMapView"
              small
              v-on="on"
            >
              <v-icon :color="!sync ? 'secondary' : ''" size="22">
                mdi-flip-horizontal
              </v-icon>
            </v-btn>
          </template>
          <span>Sync</span>
        </v-tooltip>
        <Map
          ref="map"
          :current-layers.sync="layers"
          :isDraw.sync="drawing"
          :isLoading.sync="loading"
          :type.sync="type"
          @changeArea="getDataOfBound"
          @featureInfo="onClickField"
          @zoomLevel="zoomLv => (zoomLevel = zoomLv)"
          @getWithinLayer="getWithinLayer"
        />
        <Popup ref="Popup" />
      </v-card>
      <v-layout class="map-legend" style="background: var(--v-bgListItem-base)">
        <div v-show="!drawing" style="height: 40px; border-radius: 4px">
          <v-layout align-center class="fill-height">
            <div class="fill-height pl-1" style="min-width: 180px; width: 100%">
              <SelectIndex
                :customLegend.sync="customLegend"
                :legendMenu.sync="legendMenu"
                :mapLegend.sync="mapLegend"
                :type.sync="type"
                :width="'100%'"
                left
                text
                @changeIndex="changeIndex"
              />
            </div>
            <v-spacer />
            <div v-if="type.data && group.id === 'dashboard'" class="fill-height">
              <v-btn height="40" min-height="0" min-width="0" text width="40" @click="showLegend = !showLegend">
                <v-icon v-if="!showLegend" size="22">mdi-arrow-expand</v-icon>
                <v-icon v-else size="22">mdi-arrow-collapse</v-icon>
              </v-btn>
            </div>
          </v-layout>
          <v-layout
            v-if="showLegend && type.data"
            class="px-2 py-1 mt-1"
            column
            style="height: 175px; font-size: 12px; overflow-y: auto; background: var(--v-bgListItem-base); border-radius: 8px"
          >
            <v-layout v-for="(item, i) in type.data" :key="i" align-center style="max-height: 30px">
              <div :style="{ 'background-color': item.color }" class="mr-3" style="width: 25px; height: 25px; "></div>
              <span style="font-size: 14px; width: 100px">{{ item.value }}</span>
              <span style="font-size: 14px; width: 120px">{{ item.name }}</span>
              <v-spacer />
              <div v-if="item.area" style="width: 100px">
                <span style="font-size: 14px" v-html="item.area"></span>
              </div>
              <v-spacer />
            </v-layout>
          </v-layout>
        </div>
        <v-layout v-if="currentField && !sync && !drawing" class="ml-1" style="width: 280px">
          <div class="mr-1" style="width: 245px">
            <DateRangerPicker v-model="dates" dense flat label="" left solo />
          </div>
          <div>
            <v-btn
              :disabled="loading"
              class="elevation-0"
              height="38"
              min-width="0"
              style="margin-top: 1px"
              text
              width="38"
              @click="() => $refs.fieldControl.$refs.fieldControl.getChartData(currentField,true)"
            >
              <v-icon size="20">mdi-filter-outline</v-icon>
            </v-btn>
          </div>
        </v-layout>
      </v-layout>
      <div style="position: absolute; top: 15px; right: 55px; z-index: 2">
        <FieldInfor ref="editField" />
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import Map from '@/components/Map'
import { mapState } from '@/store/ults'
import STATUS from '@/constants/record-status-constants'
import MonthPicker from '@/components/MonthPicker'
import DatePicker from '@/components/DatePicker'
import { getCluster, getGeometryOfBound, getIndices, getAoisTile } from '@/api/monitoring-api'
import AreaTransform from '@/utils/convertArea'
import SelectIndex from '@/components/SelectIndex'
import FieldInfor from './field-control/FieldInfor'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import utils from '@/utils/genUUID'
import booleanIntersects from '@turf/boolean-intersects'
import point from 'turf-point'
import colorFormat from '@/utils/ColorFormat'
import Popup from '@/components/Popup.vue'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import ZoningBar from '@/views/monitoring/field-control/ZoningBar.vue'
import { getDetailAOI } from '@/api/aoi-api'
import AOIManagement from '@/views/aoi/AOIManagement.vue'
import sleep from '@/utils/sleep'

export default {
  name: 'Zoning',
  components: {
    ZoningBar,
    LayerControl,
    Popup,
    DateRangerPicker,
    FieldInfor,
    SelectIndex,
    DatePicker,
    MonthPicker,
    Map,
    AOIManagement,
  },
  data() {
    return {
      isCreating: false,
      zoomLevel: 0,
      images: [],
      layers: [],
      addType: 'file',
      listField: [],
      loaded: false,
      indexMenu: false,
      legendMenu: false,
      chartData: [],
      myChart: undefined,
      labels: [],
      lineData: [],
      drawing: false,
      compare: {
        months: undefined,
        month: undefined,
      },
      isSmall: false,
      sync: false,
      showLegend: false,
      type: {},
      dates: [this.getBeforeDate(), this.getCurrentDate()],
      currentMonth: undefined,
      isNoData: true,
      months: [],
      autoPlay: undefined,
      currentField: undefined,
      listStatus: STATUS,
      loading: false,
      mapLegend: [],
      customLegend: [],
      compareStatistics: {},
      displayLayerControl: false,
      isShowlayerControl: false,
    }
  },
  props: {},
  mounted() {
    this.getIndex()
    this.source = 'Sentinel2 L2A'
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('legend', ['legend']),
    ...mapState('menu', ['menu', 'group']),
    ...mapState('AOI', ['listAOI', 'groups', 'filters', 'queryData', 'AOIs']),
    ...mapState('service', ['services', 'firstLoad','source']),
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 6
      }
      if (this.$vuetify.breakpoint.lg) {
        return 4
      }
      if (this.$vuetify.breakpoint.md) {
        return 3
      }
      return 1
    },
    AOIMenu() {
      return !this.sync
    },
  },
  watch: {
    currentField() {
      if (!this.mapLegend.length && this.loaded) this.getIndex()
    },
    sync(val) {
      if (val) {
        this.stopPlay()
        this.selectCompareMonth(this.compare.months[0])
      }
    },
    months(val) {
      this.compare.months = val
      this.compare.month = val[0]
    },
    queryData() {
      this.$nextTick(() => {
        if (this.zoomLevel < 11) {
          this.getFieldSmallZoom()
        } else this.getAoisTile()
      })
    },
  },
  methods: {
    editAOI() {},
    onClickField(features, point) {
      if (features[0].properties.uuid) this.selectField(features[0].properties.uuid)
    },
    async getInfoAOI(AOI) {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addToMap(AOI) {
      try {
        this.loading = true
        const res = await this.getInfoAOI(AOI)
        this.$refs.map.addGeoJsonToMap(res.name, res.geojson, 'yellow', res.uuid, 'line', true)
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    removeAOI() {
      this.$refs.map.removeAllLayer()
    },
    addZoningLayer(vectorData) {
      this.$refs.map.addGeoJsonToMap(
        'Zoning',
        vectorData.geometry,
        vectorData.color,
        vectorData.id,
        vectorData.type,
        true,
        vectorData.type,
        vectorData.style,
      )
    },
    changeMapView() {
      this.sync = !this.sync
      this.$refs.map.mapSync = this.sync
    },
    getWithinLayer(currentPoint, color, pointData) {
      if (!booleanIntersects(point(currentPoint), this.currentField.geojson)) {
        this.$refs.Popup.close()
        this.$refs.map.removePopup()
      } else {
        let html = `<div style='width: 90px; text-align: center;'>
                        <div style='float: left; width: 20px; height: 20px; background-color: ${colorFormat
                          .covertToHex(color)
                          .toUpperCase()}'></div>
                        <span style='float: left; color: black; margin-left: 10px'>${pointData.value}</span>
                        </div>`
        this.$refs.map.displayPopup(currentPoint, html, false)
      }
    },
    async getDataOfBound(zoom, bound) {
      if (this.currentField) return
      if (zoom >= 8) {
        this.$refs.map.removeCluster()
        if (this.isSmall) await this.getAoisTile()
        this.isSmall = false
      } else if (this.isSmall === false) {
        this.$refs.map.removeAllLayer()
        this.isSmall = true
        await this.getFieldSmallZoom()
        this.$emit('resetMonth')
      }
    },
    async getAoisTile() {
      try {
        let aoisLayer = this.layers.find(layer => layer.dataId == 'crop_aoi_line')
        if (aoisLayer) {
          this.$refs.map.removeLayer(aoisLayer.id)
          this.$refs.map.removeSource('source_crop_aoi_line')
        }

        const res = await getAoisTile({
          projectId: this.$route.params.id,
          payload: {
            search: this.queryData.search,
            filters: this.queryData.filters,
            service_name: this.$route.meta.service,
          },
        })
        this.$refs.map.addVectorTiles(
          {
            display: true,
            data: { type: 'vector-tiles' },
            tiles: [res.data.tile_url],
            bounds: res.data.bbox,
            layerName: 'default',
            name: 'crop_aoi_line',
            id: 'crop_aoi_line',
            paint: { 'line-color': '#3592FD', 'line-width': 2 },
          },
          true,
          false,
          'crop_aoi_line',
        )
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    async getFieldSmallZoom() {
      try {
        this.loading = true
        const res = await getCluster({
          projectId: this.$route.params.id,
          payload: {
            search: this.queryData.search,
            filters: this.queryData.filters,
            service_name: this.$route.meta.service,
          },
        })
        await sleep(200)
        this.$refs.map.displayCluster(res.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changeDate(status) {
      if (status) this.dates = [`01-01-${new Date().getFullYear() - 1}`, this.getCurrentDate()]
      else this.dates = [this.getBeforeDate(), this.getCurrentDate()]
    },
    openAddForm(type) {
      this.addType = type
    },
    startDraw() {
      this.isCreating = true
      this.drawing = true
    },
    // async startIntroduction() {
    //   await this.addStep()
    //   this.intro.start()
    // },
    async addStep() {
      // await sleep(1000)
      // let steps = IntroStep.getStepIntro()
      // this.intro.setOptions({
      //   disableInteraction: true,
      //   overlayOpacity: 0.8,
      //   exitOnOverlayClick: false,
      //   showBullets: false,
      //   steps: steps
      // }).oncomplete(() => {
      // }).onexit(() => {
      // })
    },
    changeIndex(index) {
      this.compareStatistics = index
      this.type = index
      this.indexMenu = false
      this.legendMenu = false
    },
    async getIndex() {
      try {
        const res = await getIndices({ payload: { service_name: this.$route.meta.service, image_source: 'Sentinel2 L2A' } })
        this.$store.commit('legend/SET_LEGEND', res.data)
        this.mapLegend = res.data
        this.type = res.data[0]
      } catch (e) {
      } finally {
        this.loaded = true
        this.changeLegend()
      }
    },
    changeLegend() {
      let val
      if (this.currentMonth && this.currentField) {
        val = this.mapLegend.find(val => val.code === this.type.code)
        if (val.data) {
          val.data.forEach(index => {
            if (this.currentMonth.statistics) {
              let tmpIndex = this.currentMonth.statistics.find(el => el.min_value === index.min_value)
              if (tmpIndex) index['area'] = AreaTransform.transformUnit(tmpIndex.area)
            }
          })
        }
        if (this.type.data)
          this.type.data.forEach(val => {
            if (this.currentMonth.statistics) {
              let tmpIndex = this.currentMonth.statistics.find(el => el.min_value === val.min_value)
              if (tmpIndex) val.area = AreaTransform.transformUnit(tmpIndex.area)
            }
          })
      } else {
        this.mapLegend.forEach(val => {
          if (val.data) {
            val.data.forEach(index => {
              index.area ? delete index.area : ''
            })
          }
        })
        if (this.type.data)
          this.type.data.forEach(val => {
            delete val.area
          })
      }
    },
    destroyChart() {
      this.isSmall = false
      this.currentField = undefined
      this.removeAllMap()
      this.$refs.map.changeArea()
      this.stopPlay()
      this.dates = [this.getBeforeDate(), this.getCurrentDate()]
      this.myChart ? this.myChart.destroy() : ''
      this.myChart = undefined
    },
    async selectField(uuid) {
      try {
        this.loading = true
        await this.$refs.fieldControl.selectField(this.listAOI.find(val => val.uuid === uuid))
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    removeAllMap() {
      this.$refs.map.removeAllLayer()
      this.$refs.map.removeCluster()
    },
    getCurrentDate() {
      let currentDate = new Date()
      // let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
      let month = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
      let year = currentDate.getFullYear()

      return year + '-' + month + '-' + '01'
    },
    getBeforeDate() {
      let beforeDate = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3)
      // let beforeDay = beforeDate.getDate() < 10 ? '0' + beforeDate.getDate() : beforeDate.getDate()
      // let beforeMonth = beforeDate.getMonth() < 10 ? '0' + (beforeDate.getMonth() + 1) : (beforeDate.getMonth() + 1)
      // let beforeYear = beforeDate.getFullYear()
      // let beforeDate = new Date()
      let beforeMonth = beforeDate.getMonth() + 1 < 10 ? '0' + (beforeDate.getMonth() + 1) : beforeDate.getMonth() + 1
      let beforeYear = beforeDate.getFullYear()
      return beforeYear + '-' + beforeMonth + '-' + '01'
    },
    displayTimeLine(data) {
      this.months = data
      this.currentMonth =
        this.currentMonth && this.months.some(month => month.date === this.currentMonth.date)
          ? this.months.find(month => month.date === this.currentMonth.date)
          : data[0]
      this.changeLegend()
      this.selectMonth(this.currentMonth)
    },
    displayChartData(data) {
      this.$refs.map.removeAllLayer()
      this.$refs.map.removeCluster()
      data.forEach(image => {
        image.geometry = this.currentField.geojson
      })
      this.$refs.map.addGeoJsonToMap(
        utils.getUUID(),
        {
          type: 'FeatureCollection',
          name: this.currentField.id,
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: this.currentField.geojson,
            },
          ],
        },
        '#3592FD',
        'aoi_' + this.currentField.id,
        'line',
        true,
      )
      let images = []
      let clipImages = []
      data.forEach((item, index) => {
        clipImages.push({
          id: item.id,
          data: item,
          layerType: 'Raster_Image',
          name: item.date,
          tiles: [item.clip_tile_url],
          bounds: item.bbox,
          display: index == 0,
        })
        images.push({
          id: item.id,
          data: item,
          layerType: 'Raster_Image',
          name: item.date,
          tiles: [item.tile_url],
          bounds: item.bbox,
          display: index == 0,
        })
        if (item.cloud_tile_url)
          images.push({
            id: item.id,
            data: item,
            layerType: 'Raster_Image',
            name: item.date + '_cloud',
            tiles: [item.cloud_tile_url],
            bounds: item.bbox,
            display: index == 0,
          })
      })
      // this.$refs.fieldControl.addClipImageToMap(clipImages)
      for (let i = 0; i < images.length; i++) {
        this.$refs.map.addImageToMap(images[i])
      }
      this.images = images
      // this.$refs.map.isColorPicker = true
      if (!data[0] || !data[0].statistics) {
        this.months = []
        this.isNoData = true
        return
      }

      this.isNoData = false
    },
    selectCompareMonth(month) {
      if (this.compare.month.id === month.id) return
      let currentImageLayers = this.layers.filter(layer => layer.dataId === this.compare.month.id)
      currentImageLayers.forEach(layer => {
        this.$refs.map.changeVisibleLeftLayer(layer.id)
      })
      let index = this.months.findIndex(val => val.id === this.compare.month.id)
      this.compareStatistics = JSON.parse(JSON.stringify(this.type))
      this.compareStatistics.data.forEach(val => {
        let tmpIndex = this.months[index].statistics.find(index => index.min_value === val.min_value)
        if (tmpIndex) val.area = AreaTransform.transformUnit(tmpIndex.area)
      })
      // this.$refs.map.changeVisibilityCompare(this.months[index].id)
      this.$nextTick(() => {
        this.compare.month = { ...month }
        let imageLayers = this.layers.filter(layer => layer.dataId === month.id)
        imageLayers.forEach(layer => {
          this.$refs.map.changeVisibleLeftLayer(layer.id)
        })
        this.changeLegend()
      })
    },
    selectMonth(month) {
      if (this.currentMonth && this.currentMonth.id === month.id) return
      this.layers.forEach(layer => {
        this.$refs.map.changeVisibility(layer.id, 'none')
      })
      this.currentMonth = { ...month }
      let currentImageLayers = this.layers.filter(layer => layer.dataId === this.currentMonth.id)
      currentImageLayers.forEach(layer => {
        this.$refs.map.changeVisibility(layer.id, 'visible')
      })
      this.$nextTick(() => {
        this.changeLegend()
        // this.$refs.fieldControl.changeVisibility(month)
      })
    },
    openDrawTool() {
      this.drawing = true
      this.$nextTick(() => {
        this.$refs.map.changeMode('polygon')
        this.$refs.map.reSize()
      })
    },
    download(type) {
      switch (type) {
        case 'contours':
          let dataStr =
            'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.currentField.featureCollection))
          let downloadAnchorNode = document.createElement('a')
          downloadAnchorNode.setAttribute('href', dataStr)
          downloadAnchorNode.setAttribute('download', this.currentField.name + '.geojson')
          document.body.appendChild(downloadAnchorNode) // required for firefox
          downloadAnchorNode.click()
          downloadAnchorNode.remove()
          break
        case 'tif':
          window.open(this.currentMonth.download_url, '_blank')
          break
        default:
          return
      }
    },
    startPlay() {
      let index = this.months.findIndex(val => val.id === this.currentMonth.id)
      this.autoPlay = setTimeout(() => {
        if (index + 1 < this.months.length) {
          this.selectMonth(this.months[index + 1])
          this.startPlay()
        } else {
          this.stopPlay()
        }
      }, 1000 * 3)
    },
    stopPlay() {
      if (this.autoPlay) clearTimeout(this.autoPlay)
      this.autoPlay = undefined
    },
  },
}
</script>

<style scoped>
::v-deep .select-input .v-input__control {
  box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0 0 4px rgba(137, 63, 242, 0.33);
}

::v-deep .small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.8);
  transition: none;
}
</style>
