<template>
  <v-layout class="fill-height">
    <Zoning />
  </v-layout>
</template>
<script>
import Zoning from '@/views/monitoring/Zoning.vue'

export default {
  components: { Zoning },
}
</script>

<style scoped></style>
