<template>
  <v-layout column fill-height style="overflow-y: auto; ">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div style="flex: none; height: 45px; width: 100%">
      <v-layout align-center class="fill-height px-2">
        Zoning
        <v-spacer />
        <v-btn icon small @click="() => $emit('back')">
          <v-icon style="transform: rotate(180deg)">mdi-play-circle</v-icon>
        </v-btn>
      </v-layout>
    </div>
    <v-tabs v-model="tab" :show-arrows="false" fixed-tabs height="40px">
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab style="font-size: 11px">
        Vegetation maps
      </v-tab>
      <v-tab style="font-size: 11px">
        Saved maps
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="custom-menu-tab" style="height: 100%; background-color: transparent">
      <v-tab-item style="height: 100%">
        <v-layout class="fill-height pt-3" column>
          <v-layout class="py-2" justify-center>
            <v-btn-toggle
              v-model="subTab"
              dense
              mandatory
              rounded
              style="border: 2px solid var(--v-primary-base); padding: 2.5px"
            >
              <v-btn
                :color="!subTab ? 'primary' : ''"
                :value="0"
                class="px-5"
                style="text-transform: capitalize; font-size: 11px"
                width="100"
                x-small
              >
                Parameters
              </v-btn>
              <div style="width: 2px; height: 100%; background-color: #7497fd"></div>
              <v-btn
                :color="subTab ? 'primary' : ''"
                :value="1"
                class="px-5"
                style="text-transform: capitalize; font-size: 11px"
                width="100"
                x-small
              >
                Zones
              </v-btn>
            </v-btn-toggle>
          </v-layout>
          <v-tabs-items v-model="subTab" class="custom-menu-tab" style="height: 100%; background-color: transparent">
            <v-tab-item style="height: 100%">
              <v-layout class="fill-height pa-2 pt-0" column>
                <div class="my-2">
                  <v-select
                    v-model="zoning.image_id"
                    :items="images"
                    dense
                    hide-details
                    item-text="date"
                    item-value="id"
                    outlined
                    @change="selectImage"
                  >
                    <template v-slot:prepend-inner>
                      <v-layout align-center class="fill-height">
                        <span class="mr-2" style="font-size: 12px; color: white; margin-top: 8px">Date:</span>
                      </v-layout>
                    </template>
                    <template v-slot:item="{ item }">
                      <span style="font-size: 12px">
                        <v-icon class="mr-1" small>mdi-calendar-month</v-icon>{{ item.name }}
                        <v-icon class="ml-4 mr-1" small>mdi-cloud-outline</v-icon>
                        {{ item.data.cloud_cover ? item.data.cloud_cover.toFixed(2) : 0 }} %
                      </span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span class="pt-1" style="font-size: 12px; color: white">
                        {{ item.name }}
                        <v-icon class="ml-4 mr-1" small>mdi-cloud-outline</v-icon>
                        {{ item.data.cloud_cover ? item.data.cloud_cover.toFixed(2) : 0 }} %
                      </span>
                    </template>
                    <template v-slot:append>
                      <v-tooltip color="#000000c4" max-width="400" open-on-hover right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>
                          Select an image, based on which a differential fertilizer map will be calculated.
                        </span>
                      </v-tooltip>

                      <v-hover></v-hover>
                    </template>
                  </v-select>
                </div>
                <div class="my-2">
                  <v-select
                    v-model="zoning.zone"
                    :items="zones"
                    background-color="transparent"
                    dense
                    hide-details
                    outlined
                    placeholder="Zones"
                  >
                    <template v-slot:append>
                      <v-tooltip color="#000000c4" max-width="400" open-on-hover right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>
                          The number of zones, into which the differential fertilizer map will be split. For each zone,
                          you can set different values for the amount of fertilizers or plant protection products to be
                          applied.
                        </span>
                      </v-tooltip>

                      <v-hover></v-hover>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-layout align-center class="fill-height">
                        <span class="mr-2" style="font-size: 12px; color: white; margin-top: 8px">Zones:</span>
                      </v-layout>
                    </template>
                    <template v-slot:item="{ item }">
                      <span
                        :style="{ color: item === zoning.zone ? 'var(--v-secondary-base)' : '' }"
                        style="font-size: 14px"
                        >{{ item }}</span
                      >
                    </template>
                  </v-select>
                </div>
                <v-spacer />
                <div style="width: 100%; flex: none">
                  <v-layout>
                    <v-btn
                      :disabled="!Object.keys(currentZoningData).length"
                      color="error"
                      height="45"
                      min-width="0"
                      style="border-radius: 8px"
                      width="45"
                      @click="clearZone"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-layout class="pl-2">
                      <v-btn color="primary" height="45" style="border-radius: 8px" width="100%" @click="getZoningData">
                        Calculate
                      </v-btn>
                    </v-layout>
                  </v-layout>
                </div>
              </v-layout>
            </v-tab-item>
            <v-tab-item style="height: 100%;">
              <v-layout class="fill-height"  column>
                <v-layout
                  v-if="Object.keys(currentZoningData).length"
                  class="fill-height overflow-y-auto py-3"
                  column
                  style="border-radius: 8px"
                >
                  <div class="color-text pt-5 px-2" style="font-size: 12px">Price per one unit ($/UOM)</div>
                  <div class="px-2">
                    <v-text-field
                      v-model="currentZoningData.price"
                      :min="0"
                      dense
                      hide-details
                      solo
                      solo-inverted
                      type="number"
                    ></v-text-field>
                  </div>
                  <div class="mt-1" style="height: 40px; width: 100%; flex: none; border-bottom: 2px solid black">
                    <v-layout align-center class="fill-height">
                      <div class="fill-height" style="width: 150px; font-weight: bold; flex: none">
                        <v-layout align-center class="fill-height px-3 color-text" style="font-size: 12px">
                          Zones
                        </v-layout>
                      </div>
                      <v-layout
                        align-center
                        class="fill-height pr-2 color-text"
                        style="font-size: 12px; font-weight: bold"
                      >
                        Rate (UOM/ha)
                      </v-layout>
                    </v-layout>
                  </div>
                  <div
                    v-for="(item, index) in currentZoningData.geojson ? currentZoningData.geojson.features : []"
                    v-if="currentZoningData.geojson"
                    :key="index"
                    class="my-2"
                    style="height: fit-content; width: 100%; flex: none"
                  >
                    <v-layout align-center class="fill-height">
                      <div class="fill-height" style="width: 200px; flex: none">
                        <v-layout class="fill-height px-3" column>
                          <v-layout align-center style="flex: 1">
                            <v-icon :color="currentZoningData.colors[index]" class="mr-2" size="14">
                              mdi-checkbox-blank-circle
                            </v-icon>
                            <div class="text-truncate" style="font-size: 14px">
                              {{ item.properties.zone }} / Area:
                              <span v-html="transformArea(item.properties.area)"></span>
                            </div>
                          </v-layout>
                          <v-layout align-center style="flex: 1">
                            <div class="text-truncate color-text pl-5" style="font-size: 12px">
                              Average:
                              {{ item.properties.average.toFixed(2) }}
                            </div>
                          </v-layout>
                        </v-layout>
                      </div>
                      <v-layout align-center class="fill-height pr-2">
                        <v-text-field
                          v-model="item.properties.rate"
                          :min="0"
                          dense
                          hide-details
                          solo
                          solo-inverted
                          type="number"
                        ></v-text-field>
                      </v-layout>
                    </v-layout>
                  </div>
                  <div class="py-5 pl-2 pr-2" style="flex: none; width: 100%; border-top: 2px solid black">
                    <v-layout>
                      <div style="flex: none; width: 180px">
                        <v-layout align-center class="color-text" style="font-size: 14px;">
                          Total amount of fertilizer:
                        </v-layout>
                      </div>
                      <v-layout align-center justify-end style="font-size: 14px">
                        {{ transformNumber(total) }} UOM
                      </v-layout>
                    </v-layout>
                  </div>
                  <div class="py-5 pl-2 pr-2" style="flex: none; width: 100%; border-top: 2px solid black">
                    <v-layout>
                      <div style="flex: none; width: 100px">
                        <v-layout align-center class="color-text" style="font-size: 14px;">
                          Total budget:
                        </v-layout>
                      </div>
                      <v-layout align-center justify-end style="font-size: 14px">
                        {{ transformNumber((total * currentZoningData.price).toFixed(2)) }} $
                      </v-layout>
                    </v-layout>
                  </div>
                  <div class="pa-5" style="flex: none; width: 100%; height: 200px; border-top: 2px solid black">
                    <v-layout column>
                      <div class="color-text">Total savings calculator</div>
                      <div class="color-text pt-5" style="font-size: 12px">Flat rate application (UOM/ha)</div>
                      <div>
                        <v-text-field
                          v-model="currentZoningData.flat_rate"
                          :min="0"
                          dense
                          hide-details
                          solo
                          solo-inverted
                          type="number"
                        ></v-text-field>
                      </div>
                      <div class="py-3" style="flex: none; width: 100%">
                        <v-layout>
                          <div style="flex: none; width: 185px">
                            <v-layout align-center class="color-text" style="font-size: 12px;">
                              Total amount of fertilizer:
                            </v-layout>
                          </div>
                          <v-layout align-center justify-end style="font-size: 12px">
                            {{ transformNumber(totalArea) }} UOM
                          </v-layout>
                        </v-layout>
                      </div>
                      <div class="py-5" style="flex: none; width: 100%">
                        <v-layout>
                          <div style="flex: none; width: 100px">
                            <v-layout align-center class="color-text" style="font-size: 12px;">
                              Total budget:
                            </v-layout>
                          </div>
                          <v-layout align-center justify-end style="font-size: 12px">
                            {{ transformNumber((totalArea * currentZoningData.price).toFixed(2)) }}
                            $
                          </v-layout>
                        </v-layout>
                      </div>
                    </v-layout>
                  </div>
                </v-layout>
                <div class="pa-2" style="width: 100%; flex: none">
                  <v-btn
                    :disabled="!Object.keys(currentZoningData).length || !zoning.image_id"
                    color="primary"
                    height="50"
                    style="border-radius: 8px"
                    width="100%"
                    @click="saveZone"
                  >
                    Save
                  </v-btn>
                </div>
              </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-layout>
      </v-tab-item>
      <v-tab-item>
        <v-card class="custom-card-bg-0" height="100%" width="100%">
          <v-card-text>
            <v-list color="transparent" dense>
              <v-list-item
                v-for="(map, index) in maps"
                :key="index"
                style="border-bottom: 1px solid #757575"
                @click="changeTap(map)"
              >
                <v-list-item-icon>
                  <v-icon color="button">icon-layers</v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ map.image.date }} / {{ map.index }} / {{ map.zone }} zones </v-list-item-title>
                <v-list-item-action>
                  <v-btn color="red" icon x-small @click.native.stop="deleteMap(map)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <DeleteForm ref="deleteMap" :submit-delete="submitDeleteMap" />
  </v-layout>
</template>

<script>
import { calculateZoning, deleteMap, getMapDetail, getSavedZone, saveZone } from '@/api/zoning-api'
import utils from '@/utils/genUUID'
import DeleteForm from '@/components/DeleteDialog.vue'
import AreaTransform from '@/utils/convertArea'
import numberFormat from '@/utils/comma'
import sleep from '@/utils/sleep'

export default {
  name: 'Zoning-bar',
  components: { DeleteForm },
  data() {
    return {
      loading: false,
      zoneOpacity: 1,
      totalAmountOfFertilize: {
        final: 0,
      },
      totalAmountOfFertilizeTwo: {
        final: 0,
      },
      maps: [],
      currentZoneLayers: undefined,
      currentZoningData: {},
      zoning: {
        image_id: undefined,
        zone: 3,
      },
      zones: [2, 3, 4, 5, 6],
      subTab: 0,
      tab: null,
    }
  },
  props: {
    images: { type: Array, default: () => [] },
    index: {
      type: Object,
      default: () => {},
    },
    currentField: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    images(val) {
      if (val) this.firstLoad()
    },
    tab(val) {
      if (val) this.getMaps()
      else if (this.subTab === 1) this.changeTapView()
    },
    subTab(val) {
      if (!val && this.currentZoneLayers) this.$emit('removeLayer', this.currentZoneLayers.id)
      else if (this.currentZoneLayers) this.$emit('addToMap', this.currentZoneLayers)
    },
  },
  computed: {
    totalArea() {
      let total = 0
      if (Object.keys(this.currentZoningData).length) {
        this.currentZoningData.geojson.features.forEach(feature => {
          total = total + feature.properties.area * 0.0001 * this.currentZoningData.flat_rate
        })
      }
      return total.toFixed(2)
    },
    total() {
      let total = 0
      if (Object.keys(this.currentZoningData).length) {
        this.currentZoningData.geojson.features.forEach(feature => {
          total = total + feature.properties.area * 0.0001 * feature.properties.rate
        })
      }
      return total.toFixed(2)
    },
  },
  methods: {
    async changeTapView() {
      this.subTab = 0
      await sleep(100)
      this.subTab = 1
    },
    firstLoad() {
      this.zoning.image_id = this.images[0] ? this.images[0].id : {}
      this.selectImage(this.zoning.image_id)
    },
    download() {
      if (!Object.keys(this.currentZoningData).length)
        return this.$store.commit('message/SHOW_ERROR', 'Please, select zone for download!')
      let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.currentZoningData.geojson))
      let downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute('download', this.currentField.name + '_zones.geojson')
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },
    clearZone() {
      this.currentZoningData = {}
      if (this.currentZoneLayers) {
        this.$emit('removeLayer', this.currentZoneLayers.id)
        this.currentZoneLayers = undefined
      }
    },
    setDate(id) {
      this.zoning.image_id = id
    },
    selectImage(id) {
      this.$emit(
        'selectMonth',
        this.images.find(image => image.id === id),
      )
    },
    transformNumber(number) {
      return numberFormat.numberWithCommas(number)
    },
    async saveZone() {
      try {
        this.loading = true
        const res = await saveZone({
          params: {
            flat_rate: this.currentZoningData.flat_rate ? this.currentZoningData.flat_rate : 0,
            price: this.currentZoningData.price ? this.currentZoningData.price : 0,
            geojson: this.currentZoningData.geojson,
          },
          projectId: this.$route.params.id,
          AoiId: this.currentField.uuid,
          imageId: this.zoning.image_id,
          zoneId: this.currentZoningData.uuid,
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getMaps() {
      try {
        this.loading = true
        this.maps = []
        const res = await getSavedZone({
          projectId: this.$route.params.id,
          AoiId: this.currentField.uuid,
        })
        this.maps = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    deleteMap(map) {
      let tmpMap = { ...map }
      tmpMap.name = map.image.date + '/' + map.index + '/' + map.zone + ' zones'
      this.$refs.deleteMap.openDialog(tmpMap)
    },
    async submitDeleteMap(map) {
      try {
        this.loading = true
        await deleteMap({
          projectId: this.$route.params.id,
          AoiId: this.currentField.uuid,
          zoneId: map.uuid,
        })
        await this.getMaps()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async changeTap(map) {
      try {
        this.loading = true
        this.clearZone()
        await sleep(200)
        const res = await getMapDetail({
          projectId: this.$route.params.id,
          AoiId: this.currentField.uuid,
          zoneId: map.uuid,
        })
        let image = this.images.find(val => val.name === map.image.date)
        this.zoning.image_id = image.id
        this.selectImage(image.id)
        this.zoning.zone = Number(map.zone)
        this.currentZoningData = res.data
        let vectorData = this.transformData(res.data)
        // this.$emit('addToMap', vectorData)
        this.currentZoneLayers = vectorData
        await sleep(200)
        this.tab = 0
        this.subTab = 1
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
      // this.currentZoningData = map
      // this.animateNumber(1000000, 1000, 0)
    },
    async getZoningData() {
      try {
        this.clearZone()
        this.zoning.index = this.index.code
        this.loading = true
        let data = {
          params: {
            index: this.index.code,
            zone: this.zoning.zone,
          },
          projectId: this.$route.params.id,
          AoiId: this.currentField.uuid,
          imageId: this.zoning.image_id,
        }
        const res = await calculateZoning(data)
        this.currentZoningData = res.data
        let vectorData = this.transformData(res.data)
        if (this.currentZoneLayers) this.$emit('removeLayer', this.currentZoneLayers.id)
        this.currentZoneLayers = vectorData
        this.subTab = 1
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    transformData(data) {
      return {
        id: utils.getUUID(),
        type: 'fill',
        geometry: data.geojson,
        color: 'red',
        meta: undefined,
        style: this.transformPaint(data.colors ? data.colors : []),
      }
    },
    transformPaint(colors) {
      let fillColor = ['case']
      colors.forEach((val, index) => {
        fillColor.push(['==', ['get', 'zone'], `Zone ${index + 1}`])
        fillColor.push(val)
      })
      fillColor.push('transparent')
      return {
        'fill-color': fillColor,
        'fill-opacity': 1,
      }
    },
    transformArea(area) {
      return AreaTransform.transformUnit(area)
    },
    reset() {
      this.maps = []
      this.currentZoneLayers = undefined
      this.currentZoningData = {}
      this.tab = 0
      this.subTab = 0
    },
  },
}
</script>

<style scoped></style>
